import * as React from 'react'

import { CartButton } from '@grandvisionhq/fun-cart-button'
import { WishlistMenuItem } from '@grandvisionhq/fun-wishlist/menu-item'
import { MenuItemType } from '@grandvisionhq/layout'
import { CmsLayoutSettings, withCmsLayout } from '@grandvisionhq/next'

import '@grandvisionhq/my-account/dist/index.css'
import '@grandvisionhq/fun-wishlist/styles.css'

export const settings: CmsLayoutSettings = {
  renderCartButton: (item: MenuItemType) => {
    const props = {
      key: item.label,
      label: item.label,
      href: item.link?.href,
      className: 'header-menu__icon',
    }
    return <CartButton {...props} key={props.key} />
  },
  renderWishlistMenuItem: (item: MenuItemType, tabIndex?: number) => {
    const props = {
      key: item.label || 'wishlist-button-header',
      label: item.label,
      className: 'header-menu__icon',
      tabIndex,
    }

    return <WishlistMenuItem {...props} key={props.key} />
  },
}

export default withCmsLayout(settings)
